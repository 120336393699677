
import { Fragment, useMemo, useState, useEffect, useReducer, createContext, useCallback } from "react";
import { AppContext } from "./app.context";
import { UserType } from "@/types/userType";
import Cookies from 'js-cookie';
//component
import Notification from "@/components/common/notification/notification";
import { NotificationType } from "@/components/common/notification/types";
//styles
import styles from '../../routes/login/login.module.scss';
import classNames from "classnames";
//api
import { getUser } from "@/apis/getUser";
import { APP_INITIAL_STATE, appReducer } from "./app.reducer";
import { setGameData, setUser, setAskToJoin, setToken } from "./app.actions";
import { getMapMyToken } from "@/apis/getMapMyToken";


type Props = {
  children: React.ReactNode;
};
export const IsMobileViewContext = createContext<{
  isMobileView: boolean;
  loading: boolean;
  setIsMobileView: React.Dispatch<React.SetStateAction<boolean>>;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
}>
  ({
    isMobileView: true,
    loading: true,
    setLoading: () => { },
    setIsMobileView: () => { }
  });

const AppProvider = ({ children }: Props): JSX.Element => {

  const token = Cookies.get('ACCESS_TOKEN');
  const [state, dispatch] = useReducer(
    appReducer,
    APP_INITIAL_STATE
  );

  //actions
  const setUserDetails = setUser(dispatch);
  const setGame = setGameData(dispatch);
  const setShow = setAskToJoin(dispatch);
  const setMapMyToken = setToken(dispatch);

  const [notification, setNotification] = useState<{ message: string, type: NotificationType, show: boolean }>({
    message: '',
    type: NotificationType.NORMAL,
    show: false
  })

  const [isMobileView, setIsMobileView] = useState(true);
  const [loading, setLoading] = useState(true);

  const checkViewport = () => {
    const isMobileView = window.matchMedia('(max-width: 767px)').matches;
    setIsMobileView(isMobileView);
  };

  useEffect(() => {
    checkViewport();
    window.addEventListener('resize', checkViewport);
    return () => {
      window.removeEventListener('resize', checkViewport);
    };
  }, []);

  setTimeout(() => {
    setLoading(false)
  }, 1500);


  useEffect(() => {
    if (token) {
      value.actions.getUserData(token)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const putScript = useCallback((token: string) => {
    const removescript = document.getElementById('map-my-script');
    if (removescript) {
      removescript.remove();
    }

    const script = document.createElement('script');
    script.id = 'map-my-script';
    script.src = `https://apis.mappls.com/advancedmaps/api/${token}/map_sdk_plugins?v=3.0&libraries=search`;
    script.async = true;
    document.body.appendChild(script);
  }, [])

  const fetchToken = useCallback(async () => {
    const token = localStorage.getItem('accessToken');
    if (token) {
      putScript(token);
    }
    const tokenExpiry = localStorage.getItem('expires_in');
    const expiryTimestamp = new Date(Number(tokenExpiry));
    const currentTimestamp = new Date();

    if (currentTimestamp >= expiryTimestamp) {
      try {
        const res = await getMapMyToken();
        if (res?.access_token) {
          localStorage.setItem('accessToken', res?.access_token);
          const tokenExpiry = Date.now() + res?.expires_in * 1000;
          localStorage.setItem('expires_in', String(tokenExpiry));
          if (res?.access_token) {
            putScript(res?.access_token);
          }
        }
      } catch (e) {
        console.error('error fetching token')
      }
    }


  }, [putScript])

  useEffect(() => {
    fetchToken();

    return () => {
      const script = document.getElementById('map-my-script');
      if (script) {
        script.remove();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const value = useMemo((): AppContext => ({
    ...state,
    actions: {
      getUserData: async (token) => {
        try {
          const data: UserType = await getUser(token);

          setUserDetails(data);

        } catch (e) {
          console.error('getUser error', e)
        }
      },
      showNotification: (message, type) => {
        setNotification({ show: true, message: message, type: type })
      },
      setGameData: (gameData) => {
        setGame(gameData);
      },
      setShowJoinGame: (show) => {
        setShow(show);
      },
    },
  }), [setGame, setShow, setUserDetails, state]);


  return (
    <div className={classNames(styles["App1"], styles["p-0"])}>
      <AppContext.Provider value={value}>
        <IsMobileViewContext.Provider value={{ isMobileView, setIsMobileView, loading, setLoading }}>
          <Notification onClose={() => {
            setNotification({ message: notification.message, type: notification?.type, show: false });
          }} message={notification.message} notificationType={notification?.type} show={notification.show} />
          <Fragment>{children}</Fragment>
        </IsMobileViewContext.Provider>
      </AppContext.Provider>
    </div>
  )
}

export default AppProvider;