
import fetch from 'isomorphic-fetch'; // Import fetch for server-side requests


export interface mapMyTokenDataType{
    access_token: string;
    token_type:string;
    expires_in: number,
    scope: string;
    project_code:string;
    client_id: string;
}


export const getMapMyToken = async (): Promise<mapMyTokenDataType> => {
  try {
    const response = await fetch('/api/token', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
    });

    const data:mapMyTokenDataType = await response.json();
    return data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};
