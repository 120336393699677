import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { motion, AnimatePresence } from 'framer-motion';
//styles
import styles from './notification.module.scss';
//styles
import { NotificationPropType, NotificationType } from './types';
import classNames from 'classnames';

const Notification = ({ message, onClose, notificationType, show }: NotificationPropType) => {
    const [open, setOpen] = useState<boolean>(show);

    useEffect(() => {
        setOpen(show);
        if (show) {
            setTimeout(() => {
                // setOpen(false);
                onClose();
            }, 2000)
        }

    }, [onClose, show])

    // useEffect(() => {

    //     setOpen(true);

    // }, [onClose])

    const dropIn = {
        hidden: {
            y: '-100vh',
            opacity: 0
        },
        visible: {
            y: '0',
            opacity: 1,
            transition: {
                duration: 0.4,
                type: "tween",
            },
        },
        exit: {
            y: '-100vh',
            opacity: 0,
            transition: {
                duration: 0.7,
                type: "tween",
            },
        }
    }

    const type = useMemo(() => {
        switch (notificationType) {
            case NotificationType.ERROR:
                return styles.error;
            case NotificationType.SUCCESS:
                return styles.success;
            default:
                return styles.success;
        }
    }, [notificationType])

    return (
        <AnimatePresence
            initial={false}
            exitBeforeEnter={true}>
            {open && (<motion.div
                variants={dropIn}
                initial="hidden"
                animate="visible"
                exit="exit"
                className={classNames(styles.container, styles['ph-24'], type)}>
                <div>
                    <p className={styles.title}>
                        {message}
                    </p>
                </div>
            </motion.div>)}
        </AnimatePresence>
    );
}

export default Notification